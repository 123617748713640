<template>
  <div id="formulario-edit-info">   
    <ExibirErrosConcept :errorsValidacao="errorsValidacao" ref="errosComponent"></ExibirErrosConcept>
    <div class="vx-row">
        <div class="vx-col w-full">                    
            <vx-card>
                 <div class="vx-row mb-4">
                    <div class="vx-col w-2/3 flex">         
                        <vx-tooltip text="Gravar OS sem alterar STATUS" position="bottom">              
                            <vs-button class="mt-2" size="small" @click="gravar()" color="success" icon="save" v-if="isPodeGravar">Gravar</vs-button>
                        </vx-tooltip>                        
                        <vx-tooltip text="Duplicar a OS" position="bottom">              
                            <vs-button class="ml-4 mt-2" size="small" @click="duplicar" color="primary" icon="content_copy" v-if="data_local.hasOwnProperty('id')">Duplicar</vs-button>            
                        </vx-tooltip>                        
                        <vx-tooltip text="Dar baixa no pagamento" position="bottom">              
                            <vs-button class="ml-4 mt-2" size="small" @click="baixarPagamento" icon="attach_money" color="primary" v-if="isPodeDarBaixaPgto && (data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA')">Baixar</vs-button>            
                        </vx-tooltip> 
                        <vx-tooltip :text="(data_local.faturaCobrancaCentral ? 'Fatura ' + data_local.faturaCobrancaCentral + ' já criada no SAC' : 'Criar fatura para pagamento no SAC' )" position="bottom">              
                            <vs-button class="ml-4 mt-2" size="small" @click="criarFaturaPagamento" icon="attach_money" color="primary"  v-if="exibirCampoEhCentral && (data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA')">Faturar</vs-button>            
                        </vx-tooltip> 
                         <vx-tooltip text="Registrar equipamento devolvido" position="bottom">              
                            <vs-button class="ml-4 mt-2" size="small" @click="registrarDevolucao" icon="move_to_inbox" color="primary" v-if="data_local.equipamentoNaoDevolvido && (data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA')"></vs-button>            
                        </vx-tooltip>  
                        <vx-tooltip text="Imprimir" position="bottom">              
                            <vs-button class="ml-4 mt-2" size="small" @click="exibirPopUpImpressao" icon="print" color="primary" v-if="data_local.hasOwnProperty('id') && data_local.status === 'ABERTA'"></vs-button>            
                        </vx-tooltip>                                                                       
                        <vx-tooltip text="Excluir" position="bottom">              
                            <vs-button class="ml-4 mt-2" size="small" @click="excluir" v-show="isDeletable" color="danger" icon="delete" ></vs-button>            
                        </vx-tooltip>                        
                        <vx-tooltip text="Voltar para pesquisa" position="bottom">              
                            <vs-button class="ml-4 mt-2" size="small" type="border" color="dark" @click="voltar">Voltar</vs-button>             
                        </vx-tooltip>                        
                    </div>
                    <div class="vx-col w-1/3 flex">                    
                        <vx-tooltip text="Abrir a OS" position="bottom">   
                            <vs-button class="ml-auto mt-2" size="small" @click="atualizarStatus('ABERTA')" color="dark"  style="color: black" v-if="data_local.status === 'PENDENTE' || data_local.status === 'EXECUTADA'">ABRIR</vs-button>                        
                        </vx-tooltip>
                        <vx-tooltip text="Executar a OS" position="bottom">   
                            <vs-button class="ml-2 mt-2" size="small" @click="atualizarStatus('EXECUTADA')" color="dark" style="color: black" v-if="data_local.status === 'ABERTA'">EXECUTAR</vs-button>                        
                        </vx-tooltip>
                        <vx-tooltip text="Cancelar a OS" position="bottom">   
                            <vs-button class="ml-2 mt-2" size="small" @click="atualizarStatus('CANCELADA')" color="dark" style="color: black" v-if="data_local.status === 'ABERTA'">CANCELAR</vs-button>                        
                        </vx-tooltip>
                        <vx-tooltip text="Finalizar a OS" position="bottom">   
                            <vs-button class="ml-2 mt-2" size="small" @click="atualizarStatus('FINALIZADA')" color="dark" style="color: black" v-if="data_local.status === 'EXECUTADA'">FINALIZAR</vs-button>                        
                        </vx-tooltip>
                        <vx-tooltip text="Deixar a OS Pendente" position="bottom">   
                            <vs-button class="ml-2 mt-2" size="small" @click="atualizarStatus('PENDENTE')" color="dark" style="color: black" v-if="data_local.status === 'ABERTA'">PENDENTE</vs-button>                                                
                        </vx-tooltip>
                        
                    </div>
                </div>
                <vs-tabs alignment="fixed" style="height: calc(70vh);  overflow: auto; ">
                    <vs-tab :label="getLabelTabDados">     
                        <vs-row>		                    
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"> <label class="vs-input--label" v-if="data_local.id">Número OS</label></vs-col>     
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2"> <label class="vs-input--label">Status</label></vs-col>     
                            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="!data_local.id || ( data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA'))"><label class="vs-input--label">Valor</label></vs-col>                                                             
                            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="!data_local.id || ( data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA'))"><label class="vs-input--label">Custo Técnico</label></vs-col>                                 
                            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="!data_local.id || ( data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA'))"><label class="vs-input--label">Custo Envio</label></vs-col>                                 
                            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2" v-if="!data_local.id || ( data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA'))"><label class="vs-input--label">Fatura Viagem</label></vs-col>                                 
                        </vs-row>    
                        <vs-row>		                    
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                                <h4 class="ml-3 font-medium text-success" v-if="data_local.id"> {{data_local.numeroOS}}</h4>     
                            </vs-col>     
                            <vs-col vs-type="flex" vs-justify="flex-start" vs-align="center" vs-w="2">
                                <p class="font-medium text-warning" v-if="data_local.id"> {{data_local.status}}</p>     
                                <p class="font-medium text-success" v-if="!data_local.id"> NOVA </p>     
                            </vs-col>
                            <vs-col vs-align="center" vs-w="2" v-if="!data_local.id || ( data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA'))">
                                <div class="flex items-center"> R$ <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue" v-model="data_local.valorOS" :disabled="!isPodeAlterarValorOS" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric></div>
                                <p class="font-medium text-warning text-sm" v-if="data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA')">Calculado: {{data_local.valorCalculadoOS}}</p>   
                            </vs-col>
                                                          
                            <vs-col vs-align="center" vs-w="2" v-if="!data_local.id || ( data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA'))">
                                <div class="flex items-center  ml-2"> R$ <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue" v-model="data_local.custoTecnicoTerceirizado" :disabled="isCustoTecnicoDisabled" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric></div>
                                <p v-if="data_local.faturaPagamentoTecnicoTerceirizado" class="font-medium text-warning text-sm" > Fatura: {{data_local.faturaPagamentoTecnicoTerceirizado}} </p>                                
                            </vs-col>
                           
                            <vs-col vs-align="center" vs-w="2">
                                <div class="flex items-center  ml-2">R$ <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue" v-model="data_local.custoFrete" :disabled="isCustoFreteDisabled"  :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric></div>                                
                             </vs-col>
                            <vs-col vs-align="center" vs-w="2">
                                <div class="flex items-center ml-2"><vs-input class="w-full" v-model="data_local.faturaPagamentoCustoViagem" :maxlength="60" :disabled="isFaturaCustoViagemDisabled"/> </div>                                
                            </vs-col>
                        </vs-row>
                        
                        <div class="vx-row mt-2" v-if="exibirCampoEhCentral">
                            <div class="vx-col w-full"><label class="vs-input--label">É de central ?</label></div>     
                        </div>    
                        <div class="vx-row" v-if="exibirCampoEhCentral">
                            <div class="vx-col w-full flex">                
                                <vs-checkbox v-model="data_local.isCentral" @input="fetchListaClientes"><small></small></vs-checkbox>
                                <v-select  
                                    label="nome"      
                                    v-if="data_local.isCentral"
                                    :options="listaEmpresas"  
                                    placeholder="Selecione a central"
                                    :reduce="c => c.id"        
                                    @input="fetchListaClientes"                          
                                    v-model="data_local.idCentral"                          
                                    class="w-full vs-input--label">
                                    <template v-slot:option="option">                        
                                        {{ option.nome }}
                                    </template>
                                </v-select>  
                            </div>  
                        </div>
                        <div class="vx-row  mt-2">
                            <div class="vx-col w-full"><label class="vs-input--label">Cliente</label></div>     
                        </div>  
                        <div class="vx-row">
                            <div class="vx-col w-full flex items-center">
                                <v-select  
                                    label="nome"                                                                 
                                    :options="listaClientes"  
                                    placeholder="Selecione o cliente"
                                    :reduce="c => c.id"
                                    :filter="pesquisarCliente"
                                    v-model="data_local.idCliente"                          
                                    class="w-full vs-input--label">
                                    <template v-slot:option="option">                        
                                        {{ option.nome }}
                                    </template>
                                </v-select>  
                                <div class="ml-2 flex " v-if="!data_local.id || ( data_local.id && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA'))">
                                    <vx-tooltip text="Ver tabela de preços do cliente" position="bottom">              
                                        <vs-button size="small" @click="fetchTabelaPrecos" icon="description" color="warning" v-if="!data_local.isCentral && data_local.idCliente"></vs-button>            
                                    </vx-tooltip>  
                                    <vx-tooltip text="Ver documentos do cliente" position="bottom">              
                                        <vs-button class="ml-2" size="small" @click="fetchListaDocumentosClientes" icon="folder" color="warning" v-if="!data_local.isCentral && data_local.idCliente"></vs-button>            
                                    </vx-tooltip>  
                                </div>
                            </div>     
                        </div>   
                        <div class="vx-row mt-2">
                            <div class="vx-col w-2/3"><label class="vs-input--label">Tipo</label></div>     
                            <div class="vx-col w-1/3"><label class="vs-input--label"></label></div>     
                        </div>   
                        <div class="vx-row">
                            <div class="vx-col w-2/3 flex">                
                                <v-select                      
                                    :options="CONSTANTE_TIPO_OS"  
                                    placeholder="Selecione o tipo"
                                    :reduce="c => c.data"                    
                                    v-model="data_local.tipo"                          
                                    class="w-full vs-input--label">                    
                                </v-select>  
                            </div>  
                            <div class="vx-col w-1/3">      
                                <div class="flex justify-center">
                                    <vs-checkbox v-model="data_local.installNoCliente" v-if="data_local.isCentral"><small>No Cliente</small></vs-checkbox>
                                    <vs-checkbox v-model="data_local.desintalacaoCobranca" v-if="data_local.tipo === 'DESINSTALACAO'"><small>Cobrar Desinstalação</small></vs-checkbox>
                                    <vs-checkbox v-model="data_local.desativarAutomaticamente" v-if="data_local.tipo === 'DESINSTALACAO'"><small>Desativar</small></vs-checkbox>
                                    <vs-checkbox v-model="data_local.verificacaoCobranca" v-if="data_local.tipo === 'VERIFICACAO'"><small>Cobrar Verificação</small></vs-checkbox>
                                    <vs-checkbox v-model="data_local.installKitInstalacao" v-if="data_local.isCentral && ( data_local.tipo === 'INSTALACAO' || data_local.tipo === 'INSTALACAO_ACESSORIO')"><small>Kit de Instalação? </small></vs-checkbox>                                
                                </div>
                                <div class="flex justify-center mt-2">
                                    <vs-checkbox v-model="data_local.cobrarKitBloqueio" v-if="data_local.isCentral && ( data_local.tipo === 'INSTALACAO' || data_local.tipo === 'INSTALACAO_ACESSORIO')"><small>Cobrar Kit Bloqueio? </small></vs-checkbox>
                                    <vs-checkbox v-model="data_local.cobrarKitBotaoPanico" v-if="data_local.isCentral && ( data_local.tipo === 'INSTALACAO' || data_local.tipo === 'INSTALACAO_ACESSORIO')"><small>Cobrar Kit Pânico? </small></vs-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="vx-row mt-2">
                            <div class="vx-col w-1/4"><label class="vs-input--label">Veículo</label></div>     
                            <div class="vx-col w-1/4"><label class="vs-input--label"> Agendado para ( Data / Hora )</label></div>     
                            <div class="vx-col w-1/2"><label class="vs-input--label">Técnico</label></div>  
                        </div>   
                        <div class="vx-row">
                            <div class="vx-col w-1/4">   
                                <vs-input class="w-full" v-model="data_local.placa" v-mask="['AAA-#X##', 'AAA-#X##']" icon="calendar_view_day" />
                            </div>
                            <div class="vx-col w-1/4 flex">   
                                <flat-pickr v-model="data_local.dataAgendada" :config="configDatePicker"/>
                                <vs-input class="ml-2" v-model="data_local.horaAgendada" v-mask="['##:##']" />
                            </div>
                            <div class="vx-col w-1/2 flex">                
                                <v-select       
                                    label="nome"               
                                    :options="listaTecnicos"  
                                    :reduce="t => t.id"  
                                    placeholder="Selecione o técnico"
                                    v-model="data_local.idTecnico"                          
                                    class="style-chooser w-full vs-input--label">                    
                                </v-select>  
                            </div>  
                        </div>                                      
                        <div class="vx-row mt-2">
                            <div class="vx-col w-1/3"><label class="vs-input--label">Descrição do serviço</label></div>  
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full">   
                                <vs-textarea v-model="data_local.descricao" counter="1024" />
                            </div>
                        </div>
                        
                    </vs-tab>
                    <vs-tab label="Serviços">                                               
                        <h6 class="font-medium"> PARA EXECUTAR </h6>
                         <div class="vx-row mt-2">               
                            <div class="vx-col w-1/3">
                                <div class="vx-col w-1/2"><label class="vs-input--label">Módulo 1 Reservado</label></div>   
                            </div>
                            <div class="vx-col w-1/3">
                                <div class="vx-col w-1/2"><label class="vs-input--label">Módulo 2 Reservado</label></div>   
                            </div>
                            <div class="vx-col w-1/6">
                                <div class="vx-col w-1/2"><label class="vs-input--label">Valor </label></div>   
                            </div>
                            <div class="vx-col w-1/6">
                                <div class="vx-col w-1/2"><label class="vs-input--label">Calculado</label></div>   
                            </div>                            
                        </div>
                         <div class="vx-row">                                 
                            <div class="vx-col w-1/3">
                                <vs-input class="w-full" v-model="data_local.codigoModulo1Reservado" :maxlength="60"/> 
                            </div>
                            <div class="vx-col w-1/3">
                                <vs-input class="w-full" v-model="data_local.codigoModulo2Reservado" :maxlength="60"/> 
                            </div>
                            <div class="vx-col w-1/6 flex items-center" v-if="data_local.id && ( data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA') && isPodeDarBaixaPgto">
                                <vue-autonumeric  class="vs-inputx vs-input--input normal hasValue w-full" v-model="data_local.valorOS" :options="[{ digitGroupSeparator: '.', decimalCharacter: ',', minimumValue: '0', decimalPlaces:'2' }]"></vue-autonumeric>                                                                                           
                            </div>
                            <div class="vx-col w-1/6 flex items-center" v-if="data_local.id && ( data_local.status === 'EXECUTADA' || data_local.status === 'FINALIZADA') && isPodeDarBaixaPgto">                                
                                <p class="font-medium text-warning w-full ml-2"> R$  {{data_local.valorCalculadoOS}}</p>     
                            </div>
                        </div>
                        <div class="vx-row mt-2">                           
                            <div class="vx-col w-full flex items-center">
                                <vs-checkbox v-model="data_local.installModulo" class="w-full" :disabled="data_local.tipo === 'INSTALACAO_ACESSORIO'"><small>Rastreador</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installBloqueio" class="w-full"><small>Bloqueio</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installBotaoPanico" class="w-full"><small>Botão de Pânico</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installSensorOdometro" class="w-full"><small>Sensor de Odômetro</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installSensorRPM" class="w-full"><small>Sensor de RPM</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installSensorTanque" class="w-full"><small>Sensor de Tanque</small></vs-checkbox>
                            </div>     
                        </div>    
                        <div class="vx-row mt-2">                           
                            <div class="vx-col w-full flex items-center">
                                <vs-checkbox v-model="data_local.installSensorSimples" class="w-full"><small>Sensor Simples</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installAdaptadorLeitorCAN" class="w-full"><small>Adapt. de Leitor CAN</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installLeitorCAN" class="w-full"><small>Leitor de CAN</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installIdentificadorMotorista" class="w-full"><small>Identificador de Motorista</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installTeclado" class="w-full"><small>Teclado</small></vs-checkbox>
                                <vs-checkbox v-model="data_local.installSensorRS232" class="w-full"><small>Sensor RS232</small></vs-checkbox>
                            </div>     
                        </div>
                        <div class="vx-row mt-2" v-if="data_local.tipo === 'SUBSTITUICAO' ">                           
                            <div class="vx-col w-full flex items-center">
                                <vs-checkbox v-model="data_local.installSimcard" class="w-full"><small>Simcard</small></vs-checkbox>
                            </div>
                        </div>                            
                        <vs-divider />
                        <h6 class="font-medium" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'" >EXECUTADOS </h6>

                        <div class="vx-row mt-2" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'">               
                            <div class="vx-col w-1/4"><label class="vs-input--label">Data Execução</label></div>     
                            <div class="vx-col w-1/4" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA' && data_local.tipo !== 'DESINSTALACAO' && data_local.tipo !== 'INSTALACAO_ACESSORIO'">
                                <label class="vs-input--label">Módulo Instalado</label>
                            </div>
                            <div class="vx-col w-1/4" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA' && data_local.tipo !== 'INSTALACAO' && data_local.tipo !== 'INSTALACAO_ACESSORIO'">
                                <label class="vs-input--label">Módulo Desinstalado</label>
                            </div>
                            <div class="vx-col w-1/4"><label class="vs-input--label">Equipamento Não Devolvido ?</label></div>     
                        </div>
                        <div class="vx-row" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'">    
                             <div class="vx-col w-1/4 flex">   
                                <flat-pickr v-model="data_local.dataExecutada" :config="configDatePicker"/>
                                <vs-input class="ml-2" v-model="data_local.horaExecutada" v-mask="['##:##']" />
                            </div>                       
                            <div class="vx-col w-1/4" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA' && data_local.tipo !== 'DESINSTALACAO' && data_local.tipo !== 'INSTALACAO_ACESSORIO'">
                                <vs-input class="w-full" v-model="data_local.codigoModuloInstalado" :maxlength="60"/> 
                            </div>
                            <div class="vx-col w-1/4" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA' && data_local.tipo !== 'INSTALACAO' && data_local.tipo !== 'INSTALACAO_ACESSORIO'">
                                <vs-input class="w-full" v-model="data_local.codigoModuloDesinstalado" :maxlength="60"/> 
                            </div>
                            <div class="vx-col w-1/4 flex">   
                                <vs-checkbox color="danger" v-model="data_local.equipamentoNaoDevolvido"></vs-checkbox>
                                <vx-tooltip text="Data prevista para devolução" position="bottom">              
                                    <flat-pickr v-model="data_local.dataPrevisaoDevolucaoEquipamento" :config="configDatePicker" v-if="data_local.equipamentoNaoDevolvido"/>
                                </vx-tooltip>
                            </div>
                        </div>
                        <div class="vx-row mt-2" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'">                           
                            <div class="vx-col w-full flex items-center">
                                <vs-checkbox color="success" v-model="data_local.installModuloExecutado" class="w-full" :disabled="data_local.tipo === 'INSTALACAO_ACESSORIO'"><small>Rastreador</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installBloqueioExecutado" class="w-full"><small>Bloqueio</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installBotaoPanicoExecutado" class="w-full"><small>Botão de Pânico</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installSensorOdometroExecutado" class="w-full"><small>Sensor de Odômetro</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installSensorRPMExecutado" class="w-full"><small>Sensor de RPM</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installSensorTanqueExecutado" class="w-full"><small>Sensor de Tanque</small></vs-checkbox>
                            </div>     
                        </div>    
                        <div class="vx-row mt-2" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'">                           
                            <div class="vx-col w-full flex items-center">
                                <vs-checkbox color="success" v-model="data_local.installSensorSimplesExecutado" class="w-full"><small>Sensor Simples</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installAdaptadorLeitorCANExecutado" class="w-full"><small>Adapt. de Leitor CAN</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installLeitorCANExecutado" class="w-full"><small>Leitor de CAN</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installIdentificadorMotoristaExecutado" class="w-full"><small>Identificador de Motorista</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installTecladoExecutado" class="w-full"><small>Teclado</small></vs-checkbox>
                                <vs-checkbox color="success" v-model="data_local.installSensorRS232Executado" class="w-full"><small>Sensor RS232</small></vs-checkbox>
                            </div>     
                        </div>       
                        <div class="vx-row mt-2" v-if="data_local.hasOwnProperty('id') && data_local.tipo === 'SUBSTITUICAO' && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'">
                            <div class="vx-col w-full flex items-center">
                                <vs-checkbox color="success" v-model="data_local.installSimcardExecutado" class="w-full"><small>Simcard</small></vs-checkbox>
                            </div>
                        </div>                 
                        <div class="vx-row mt-2" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'">
                            <div class="vx-col w-1/3"><label class="vs-input--label">Descrição da execução</label></div>  
                        </div>
                        <div class="vx-row" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA'">
                            <div class="vx-col w-full">   
                                <vs-textarea v-model="data_local.resultado" counter="4096" rows="4" />
                            </div>
                        </div>   
                        <div class="vx-row">
                            <div class="vx-col w-1/3"><label class="vs-input--label">Descrição do serviço</label></div>  
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full">   
                                <vs-textarea v-model="data_local.descricao" readonly="true" style="backgroundColor: navy" />
                            </div>
                        </div>   
                    </vs-tab>                
                    <vs-tab :label="'Fotos e Vídeos (' + listaArquivoAnexos.length + ')'" v-if="data_local.id">
                        <vs-button class="mb-4" color="primary" type="filled" icon="refresh" @click="fetchListaArquivosAnexos()" size="small"> Atualizar lista arquivo anexos </vs-button>
                        <div class="vx-row flex">
                            <div class="vx-col w-3/4">
                                <div class="flex-wrap">
                                    <div v-for="arquivo in listaArquivoAnexos" :key="arquivo.id" class="imgHolder">
                                        <img :src="arquivo.caminhoDocumento"  style="max-width: 210px;" @click="verDocumento(arquivo)" v-if="isFileImage(arquivo.caminhoDocumento)"/>
                                        <embed
                                           v-if="isFilePDF(arquivo.caminhoDocumento)"
                                            :src="arquivo.caminhoDocumento"
                                            type="application/pdf"
                                            frameBorder="0"
                                            scrolling="auto"                                            
                                            height="100%"
                                            width="100%"
                                        />
                                        <video width="100%" height="100%" controls v-if="isFileVideo(arquivo.caminhoDocumento)">
                                            <source :src="arquivo.caminhoDocumento">                                        
                                            Your browser does not support the video tag.
                                        </video>
                                        <span @click="verDocumento(arquivo)" class="w-full mb-2 font-bold">{{arquivo.legenda}}</span>
                                        <div class="-full flex">
                                            <vs-button color="danger" size="small" type="filled" icon="delete" @click="excluirAnexo(arquivo)" class="ml-auto " 
                                            v-if="data_local.status !== 'PENDENTE' && data_local.status !== 'CANCELADA' && data_local.status !== 'FINALIZADA'"></vs-button>
                                            <vs-button color="primary" size="small" type="filled" icon-pack="feather" icon="icon-download" @click="verDocumento(arquivo)" class="ml-2 mr-auto"></vs-button>
                                        </div>
                                    </div>
                                </div>
                            </div>                        
                            <div class="vx-col w-1/4 flex-items-center" v-if="data_local.status !== 'CANCELADA'">
                                    <vs-input class="w-full" v-model="dadosUpload.legendaArquivo" :maxlength="255" placeholder="Informe a legenda para o arquivo"/> 
                                    <vs-upload :action="getUrlPost" @on-success="successUploadFile" @on-delete="limparMsgErroUpload"
                                            text="Anexar ..." :data="dadosUpload" limit="1" multiple="false" fileName="nomeArquivo"
                                            automatic single-upload/>
                                    <vs-alert closable title="Atenção" color="warning" :active.sync="exibirMensagemResultadoUpload">{{mensagemResultadoUpload}}</vs-alert>
                            </div>
                        </div>
                    </vs-tab>   
                    <vs-tab label="Aprovação" v-if="data_local.id && isAcessoConcept && ( data_local.status === 'ABERTA' || data_local.status === 'EXECUTADA')">
                        <div class="vx-row">
                            <div class="vx-col w-1/3"><label class="vs-input--label">Email do Aprovador</label></div>  
                            <div class="vx-col w-1/3"></div>  
                            <div class="vx-col w-1/3"><label class="vs-input--label">Enviado em</label></div>  
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-1/3">   
                                <vs-input class="w-full mb-1" placeholder="Email do aprovador" v-model="data_local.emailAssinaturaEletronica" maxlength="45" />                              
                            </div>
                            <div class="vx-col w-1/3">   
                                <vx-tooltip text="Enviar Para Aprovação" position="bottom">              
                                    <vs-button class="ml-4 mt-2" size="small" @click="enviarParaAprovacao" icon="thumb_up_alt" color="primary" v-if="data_local.hasOwnProperty('id') && data_local.status !== 'CANCELADA' ">Enviar para Aprovação</vs-button>            
                                </vx-tooltip>
                            </div>
                            <div class="vx-col w-1/3">   
                                <p v-if="data_local.dataEnvioAssinaturaEletronica"> {{ data_local.dataEnvioAssinaturaEletronica }}</p>
                            </div>

                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-1/3"><label class="vs-input--label">Texto para envio de aprovação do cliente</label></div>  
                        </div>
                        <div class="vx-row">
                            <div class="vx-col w-full">  
                                <vs-textarea v-model="data_local.textoAprovacao" counter="4096" rows="10" />
                            </div>
                        </div>
                        <div class="vx-row" v-if="data_local.urlParaAssinaturaEletronica">
                            <div class="vx-col w-full flex items-center">  
                                <vs-button @click="copyURL" color="primary" icon="content_copy"></vs-button> 
                                <p class="ml-4"> Link para aprovação: <strong> {{data_local.urlParaAssinaturaEletronica}} </strong></p>                                
                            </div>
                        </div>
                    </vs-tab>
                </vs-tabs>
            </vx-card> 
        </div>
    </div>
    <vs-popup fullscreen :title="`Ordem de Serviço - ${this.data_local.numeroOS}`" :active.sync="popUpImpressao.exibir" v-if="popUpImpressao.exibir">
        <ViewRelatorio v-if="dadosPDF" :sourcePDF="dadosPDF" class="mt-2"
            @exportarPDF="exportarRelatorio('PDF')" @exportarXLS="exportarRelatorio('XLS')" 
            @exportarTXT="exportarRelatorio('TXT')" @exportarRTF="exportarRelatorio('RTF')" />   
    </vs-popup>
    <vs-popup title="Tabela de Preços" :active.sync="popUpTabelaPrecos.exibir" v-if="popUpTabelaPrecos.exibir">
        <vs-row vs-justify="center">
            <vs-col vs-justify="flex-start" vs-align="center" vs-w="10">  
                <h5>Instalação</h5>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"> <small> Rastreador GSM  </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"> <small> R$ {{tabelaPreco.instalacao}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Rastreador GSM com Bloqueio: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoRastreadorBloqueio}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Rastreador GSM com Bloqueio e Pânico: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoRastreadorBloqueioPanico}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Satelite: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoSAT}} </small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Rastreador GSM + Satelite: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoGSMeSAT}} </small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Desinstalação: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.desinstalacao}} </small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Bloqueio: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.bloqueio}} </small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Sensor RPM: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.sensorRpm}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Sensor Odômetro: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.odometro}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Sensor Tanque: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.sensorTanque}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Sensor Simples: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.sensorSimples}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Botão de Pânico: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.botaoPanico}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Indentificador Motorista: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoIdentificadorMotorista}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Sensor RS232: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoRS232}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Telemetria: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoTelemetria}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Travas: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoTravas}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Atuador Simples: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.instalacaoAtuadorSimples}}</small></vs-col>
                </vs-row>
            </vs-col>  
        </vs-row>
        <vs-row vs-justify="center" class="mt-4">
            <vs-col vs-justify="flex-start" vs-align="center" vs-w="10">  
                <h5>Mensalidade</h5>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Mensalidade GSM: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeGSM}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Mensalidade Satelite: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeSAT}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Mensalidade GSM + Satelite: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeSATeGSM}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Mensalidade SMS: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeSMS}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Franquia SMS: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.franquiaSMS}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Mensalidade Backup: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeBKp}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Mensalidade Celular: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeCelular}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Mensalidade Moto: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeMoto}}</small></vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Adicional Mensalidade Chip Multioperadora: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeChih6Multioperadora}}</small></vs-col>
                </vs-row>                           
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Adicional Mensalidade Identificador de Motorista: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeIdentificadorMotorista}}</small></vs-col>
                </vs-row>                           
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Adicional Mensalidade Atuador Entrada 1 a 3: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeAtuadorSaida1a3}}</small></vs-col>
                </vs-row>                           
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Adicional Mensalidade Sensor RS232: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeRS232}}</small></vs-col>
                </vs-row>                           
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Adicional Mensalidade Teclado: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeTeclado}}</small></vs-col>
                </vs-row>                           
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Adicional Mensalidade Telemetria: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeTelemetria}}</small></vs-col>
                </vs-row>                           
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Adicional Mensalidade Saida 1 a 3: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.mensalidadeSensorEntradas1a3}}</small></vs-col>
                </vs-row>                           
                <vs-row vs-justify="center">
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Verificação Visita: </small></vs-col>
                    <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>R$ {{tabelaPreco.visitaVerificacao}}</small></vs-col>
                </vs-row>  
            </vs-col>            
        </vs-row>
        <vs-row vs-justify="center" class="mt-4">
            <vs-col vs-justify="center" vs-w="10">           
                    <h5> Fidelização</h5>
                    <vs-row vs-justify="center">
                        <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Fidelização GSM</small></vs-col>
                        <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>{{tabelaPreco.fidelizacaoGSM}} meses</small></vs-col>
                    </vs-row>
                    <vs-row vs-justify="center">
                        <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Fidelização SAT </small></vs-col>
                        <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>{{tabelaPreco.fidelizacaoSAT}} meses</small></vs-col>
                    </vs-row>
                    <vs-row vs-justify="center">
                        <vs-col vs-justify="flex-start" vs-align="center" vs-w="8"><small>Fidelização GMS e SAT</small></vs-col>
                        <vs-col vs-justify="flex-start" vs-align="center" vs-w="4"><small>{{tabelaPreco.fidelizacaoGSMeSAT}} meses</small></vs-col>
                    </vs-row>
            </vs-col>
        </vs-row>   
    </vs-popup>
    <vs-popup title="Proposta Comercial e outros Arquivos" :active.sync="popUpAnexosClientes.exibir" v-if="popUpAnexosClientes.exibir">
        <div class="vx-row">
            <div class="vx-col w-full">
                <vx-card class="mt-2" v-if="dadosDaGridAnexos.length">
                    <div class="flex items-center flex-start">
                        <vs-button class="mt-2 mr-2 mb-4" size="small" @click="verDocumentoCliente" color="primary" icon="search"></vs-button> 
                    </div>
                    
                    <ag-grid-vue
                        ref="agGridTableAnexos"        
                        :gridOptions="configuracaoGridAnexo.gridOptions"
                        class="ag-theme-material ag-grid-table ag-grid-altura"
                        :columnDefs="configuracaoGridAnexo.columnDefs"
                        :defaultColDef="configuracaoGridAnexo.defaultColDef"
                        :rowData="dadosDaGridAnexos"
                        :headerHeight="25"
                        :rowHeight="25"
                        rowSelection="single"
                        @grid-ready="onGridReadyAnexo"                            
                        colResizeDefault="shift"                    
                        :animateRows="true"
                        :floatingFilter="false"
                        :pagination="false"
                        :paginationAutoPageSize="false"
                        :suppressPaginationPanel="true"
                        >
                    </ag-grid-vue>
                    
                </vx-card> 
            </div>            
        </div>
    </vs-popup>
  </div>
</template>

<script>
import axios from "@/axios.js"
import { AgGridVue } from "ag-grid-vue"
import VueAutonumeric from 'vue-autonumeric/src/components/VueAutonumeric.vue';
import { CONSTANTE_TIPO_OS } from '@/constantesComboBox.js'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import {Portuguese as PortugueseLocale} from 'flatpickr/dist/l10n/pt.js';
import ViewRelatorio from '@/views/components/ViewRelatorioJasper'

const URL_SERVLET = "/OrdemServicoRest";
const NAME_ROUTE_SEARCH = "cadastro-ordem-servico-pesquisar";

export default {  
  components: {  
    flatPickr,
    ViewRelatorio,
    VueAutonumeric,
    AgGridVue
  },    
  props: {
    registroOriginal: {
      type: Object,
      required: false,
      default: function () {
        return {
            idCliente: null, numeroOS: null, tipo: null, installNoCliente: false, installKitInstalacao: false, verificacaoCobranca: false, desativarAutomaticamente: false, 
            desintalacaoCobranca: false, idCentral: null, placa: null, dataAgendada: null, horaAgendada: null, idTecnico: null, descricao: null, textoAprovacao: null, 
            resultado: null, emailAssinaturaEletronica: null, codigoModuloInstalado: null, codigoModuloDesinstalado: null, installModulo: false, installBloqueio: false, 
            installSensorSimples: false, installBotaoPanico: false, installSensorOdometro: false, installSensorRPM: false, installSensorTanque: false, installAdaptadorLeitorCAN: false, 
            installLeitorCAN: false,  installIdentificadorMotorista: false, installTeclado: false, installModuloExecutado: false, installBloqueioExecutado: false, 
            installBotaoPanicoExecutado: false,  installSensorOdometroExecutado: false, installSensorRPMExecutado: false, installSensorTanqueExecutado: false, 
            installSensorSimplesExecutado: false, installAdaptadorLeitorCANExecutado: false, installLeitorCANExecutado: false,  installIdentificadorMotoristaExecutado: false, 
            installTecladoExecutado: false, codigoModulo1Reservado: null, codigoModulo2Reservado: null, valorCalculadoOS: null, custoTecnicoTerceirizado: null, custoFrete: null, 
            faturaPagamentoCustoViagem: null, installSimcard: false, installSimcardExecutado: false, equipamentoNaoDevolvido: false, dataPrevisaoDevolucaoEquipamento: null, cobrarKitBloqueio: false, cobrarKitBotaoPanico: false }
        }
    },
    preDados: {
       type: Object,
       required: false
    }
  },
  data() {
    return {        
        errorsValidacao: [],  
        data_local: JSON.parse(JSON.stringify(this.registroOriginal)),   
        listaClientes : [],    
        listaEmpresas: [],  
        listaTecnicos: [],
        configDatePicker: {          
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "d/m/Y", 
            altInputClass: "vs-inputx vs-input--input normal hasValue text-center",
            locale: PortugueseLocale
        },  
        dadosPDF: null,              
        popUpImpressao: {
            exibir: false,          
        },       
        dadosUpload: {
           ordemServicoId: null,                    
           destino: "ordemServico",
           empresaId: this.$store.state.AppActiveUser.idEmpresa,  
           legendaArquivo: null,          
           outputFormat: 'JSON',           
        },
        mensagemResultadoUpload: null,
        exibirMensagemResultadoUpload: false,
        listaArquivoAnexos: [],        
        popUpTabelaPrecos: {
            exibir: false,                  
        },
        tabelaPreco: {
            idCliente: null,
            nome: null,
            instalacao: 165,
            desinstalacao: 100,
            bloqueio: 75,
            sensorRpm: 150,
            odometro: 150,
            sensorSimples: 100,
            sensorTanque: 350,
            botaoPanico: 45,
            visitaVerificacao: 50,
            mensalidadeGSM: 80,
            mensalidadeSAT: 125,
            mensalidadeSMS: 0,
            franquiaSMS: 0,
            mensalidadeBKP: 85,
            mensalidadeSATeGSM: 165,
            mensalidadeIdentificadorMotorista: 10,
            mensalidadeAtuadorSaida1a3: 5,
            mensalidadeRS232: 15,
            mensalidadeTeclado: 10,
            mensalidadeTelemetria: 65,
            mensalidadeSensorEntradas1a3: 5,
            mensalidadeMoto: 50,
            mensalidadeChipMultioperadora: 10,
            mensalidadeCelular: 5,
            instalacaoRastreadorBloqueio: 165,
            instalacaoRastreadorBloqueioPanico: 165,
            instalacaoIdentificadorMotorista: 215,
            instalacaoTelemetria: 110,
            instalacaoRS232: 100,
            instalacaoTravas: 850,
            instalacaoAtuadorSimples: 100,
            instalacaoSAT: 275,
            instalacaoGSMeSAT: 326,
            fidelizacaoGSM: 24,
            fidelizacaoSAT: 36,
            fidelizacaoGSMeSAT: 36,
        },
        popUpAnexosClientes: {
            exibir: false
        },
        configuracaoGridAnexo: { 
          gridApi: null, gridOptions: {}, 
          defaultColDef: { sortable: true, resizable: true, suppressMenu: true  },
          columnDefs: [                                
            { headerName: 'Arquivo',  field: 'nomeDocumento', width: 400},        
            { headerName: 'Criado às',  field: 'dataUpload', width: 200}            
          ]
        },
        dadosDaGridAnexos: [],
    }
  },  
  created(){
      this.CONSTANTE_TIPO_OS = CONSTANTE_TIPO_OS;
  }, 
  mounted() {
        this.fetchListaClientes();
        this.fetchListaEmpresas();
        this.fetchListaTecnicos();
        if (this.registroOriginal.hasOwnProperty("id")) {
            this.dadosUpload =  {
                ordemServicoId: this.data_local.id,                    
                destino: "ordemServico",
                empresaId: this.$store.state.AppActiveUser.idEmpresa,  
                legendaArquivo: null,          
                outputFormat: 'JSON',           
            };
            this.fetchListaArquivosAnexos();
        } else if (this.preDados) {
            this.data_local.idCliente = this.preDados.idCliente;
            this.data_local.placa = this.preDados.placa;
        }
  },
  computed: {    
    isPodeGravar() {
        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "ADMINISTRADOR" || perfilLogado === "EMPRESA" ) {
            return !this.data_local.id || this.data_local.status === 'ABERTA' || this.data_local.status === 'PENDENTE' || this.data_local.status === 'EXECUTADA'
        } else {
            return !this.data_local.id || this.data_local.status === 'ABERTA' || this.data_local.status === 'PENDENTE'
        }
    },
    isAcessoConcept() {
        return this.$store.state.AppActiveUser.idEmpresa === 1;
    },  
    getUrlPost() {
      return axios.defaults.baseURL + "/UploadDocumentos?processUploadedFile";
    },  
    isDeletable() {
       return this.data_local != null && this.data_local.hasOwnProperty("id") && ( this.data_local.status === 'ABERTA' || this.data_local.status === 'PENDENTE');
    },    
    exibirCampoEhCentral() {
        if ( this.$store.state.AppActiveUser.idEmpresa === 1) {
            return true;
        }

        return false;
    },
    getLabelTabDados(){
        if (this.data_local.hasOwnProperty('id')) {
            return "Dados - " + this.data_local.numeroOS + " - " + this.data_local.status;
        } else {
            return "Dados - Nova OS";
        }
    },
    isPodeAlterarValorOS() {
        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "SUPERVISOR" || perfilLogado === "ADMINISTRADOR" || perfilLogado === "FINANCEIRO" || perfilLogado === "EMPRESA" ) {
            return true;
        }
        return false;
    },
    isPodeDarBaixaPgto() {
        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "ADMINISTRADOR" || perfilLogado === "EMPRESA" ) {
            return true;
        }
        return false;
    } ,
    isCustoTecnicoDisabled() {
        if (this.data_local.faturaPagamentoTecnicoTerceirizado) {
            if (this.isPodeDarBaixaPgto) {
                return false;
            }
            return true;
        } else if (this.data_local.status === 'EXECUTADA' || this.data_local.status === 'FINALIZADA') {
            if (this.isPodeDarBaixaPgto) {
                return false;
            }
            return true;
        }
        return false;
    },
    isCustoFreteDisabled() {
        if (this.data_local.status === 'EXECUTADA' || this.data_local.status === 'FINALIZADA') {
            if (this.isPodeDarBaixaPgto) {
                return false;
            }
            return true;
        }
        return false;
    },
    isFaturaCustoViagemDisabled() {
        if (this.registroOriginal.faturaPagamentoCustoViagem) {
            if (this.isPodeDarBaixaPgto) {
                return false;
            }
            return true;
        } else if (this.data_local.status === 'EXECUTADA' || this.data_local.status === 'FINALIZADA') {
            if (this.isPodeDarBaixaPgto) {
                return false;
            }
            return true;
        }
        return false;
    },
  },
  methods: {  
    criarFaturaPagamento() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja criar a fatura para pagamento no SAC ?', acceptText: 'Sim', cancelText: 'Não', accept: this.criarFaturaPagamentoRequest
        });
    },
    criarFaturaPagamentoRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('idOS', this.data_local.id);   
        params.append('acao', 'CRIAR_CONTA_RECEBER_CENTRAL');   
                        
        axios.post('/BaixaPagamentoFaturaOS', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.Conta.erroOperacao) {
                    this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.Conta.mensagemResultadoOperacao, acceptText: 'OK'})
                } else {
                    this.$vs.notify({ color: 'success', title: 'Aviso', text: response.data.Conta.mensagemResultadoOperacao, acceptText: 'OK'})
                    this.$router.push({ name: NAME_ROUTE_SEARCH, params: {numeroOrdemServico: this.data_local.numeroOS } })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    }, 
    registrarDevolucao() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Registrar devolução do equipamento ?', acceptText: 'Sim', cancelText: 'Não', accept: this.registrarDevolucaoRequest
        });
    },
    registrarDevolucaoRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();                
        params.append('method', 'EQUIPAMENTO_DEVOLVIDO');      
        params.append('id', this.data_local.id);   
        params.append('idCliente', this.data_local.idCliente);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    }, 
    isFileVideo(arquivo) {
        return (arquivo.indexOf(".mp4") != -1 || arquivo.indexOf(".ogg") != -1 || arquivo.indexOf(".avi") != -1)
    },
    isFilePDF(arquivo) {
        return (arquivo.indexOf(".pdf") != -1)
    },
    isFileImage(arquivo) {
        return (arquivo.indexOf(".jpeg") != -1 || arquivo.indexOf(".jpg") != -1 || arquivo.indexOf(".png") != -1)
    },
    async copyURL() {
        try {
            await window.navigator.clipboard.writeText(this.data_local.urlParaAssinaturaEletronica);            
        } catch(e) {
            /* eslint-disable no-console */ console.log("Erro copyURL " + e);        
        }
    },
    fetchListaDocumentosClientes() {
        const params = new URLSearchParams();
        params.append('method', 'LISTAR_DOCUMENTOS');
        params.append('outputFormat', 'JSON');
        params.append('id', this.data_local.idCliente);

        axios.post("/ClientesRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {                             
                this.dadosDaGridAnexos = response.data;    
                this.popUpAnexosClientes.exibir = true;
        })
        .catch((error) => { 
            /* eslint-disable no-console */ console.log("Erro fetchListaDocumentos " + error);        
        })      
    }, 
    verDocumentoCliente() { 
        var itemSelecionados = this.configuracaoGridAnexo.gridApi.getSelectedRows();

        if (itemSelecionados && itemSelecionados.length > 0) {
            let ultimoIndice = itemSelecionados[0].nomeDocumento.lastIndexOf(".") ;
            if ( ultimoIndice != -1) {
                window.open("https://documentossetup.s3.amazonaws.com/" + this.data_local.idCliente + "/" + itemSelecionados[0].id + "." + itemSelecionados[0].nomeDocumento.substring(ultimoIndice+1), "_blank");    
            } else {
                window.open("https://documentossetup.s3.amazonaws.com/" + this.data_local.idCliente + "/" + itemSelecionados[0].id, "_blank");    
            }
            
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    },
    onGridReadyAnexo() {
       this.configuracaoGridAnexo.gridApi = this.configuracaoGridAnexo.gridOptions.api;
       this.configuracaoGridAnexo.gridApi.sizeColumnsToFit();      
    }, 
    exibirPopUpImpressao() {
        this.popUpImpressao.exibir = true;
        this.gerarRelatorio();
    },
    pesquisarCliente(options, varTermoPesquisa) {
        varTermoPesquisa = varTermoPesquisa.toUpperCase();
        return options.filter(function (c) {
                return (c.nome.toUpperCase().indexOf(varTermoPesquisa) != -1  || c.razaoSocial.toUpperCase().indexOf(varTermoPesquisa) != -1 || 
                c.CNPJCPF.toUpperCase().indexOf(varTermoPesquisa) != -1);
        })
    }, 
    excluir() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja excluir ?', acceptText: 'Sim', cancelText: 'Não', accept: this.excluirRequest
        });
    },
    excluirRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('method', 'Delete');      
        params.append('id', this.data_local.id);   
        params.append('idCliente', this.data_local.idCliente);   
                
        axios.post(URL_SERVLET, params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    },
    duplicar() {
        if (this.formularioValido(false)) {
            delete this.registroOriginal.id;
            this.gravarRequest('ABERTA', true);
        }
    },
    atualizarStatus(novoStatus) {
        if (this.formularioValidoAlteracaoStatus(novoStatus)) {

            if (novoStatus === 'EXECUTADA' || novoStatus === 'FINALIZADA') {
                this.$vs.dialog({
                    type: 'confirm', color: 'warning', title: 'Atenção', text: 'Equipamento já foi devolvido ?', acceptText: 'Sim', cancelText: 'Não',                     
                    accept: ()=> { this.data_local.equipamentoNaoDevolvido = false; this.gravarRequest(novoStatus) },
                    cancel: ()=> { this.data_local.equipamentoNaoDevolvido = true; this.gravarRequest(novoStatus) }
                });
            } else {
                this.gravarRequest(novoStatus);
            }
        }
    },
    gravar() {    
        if (this.data_local.status === 'EXECUTADA' || this.data_local.novoStatus === 'FINALIZADA') {
            this.$vs.dialog({
                type: 'confirm', color: 'warning', title: 'Atenção', text: 'Equipamento já foi devolvido ?', acceptText: 'Sim', cancelText: 'Não', 
                accept: ()=> { this.data_local.equipamentoNaoDevolvido = false; this.gravarRequest(null, false) },
                cancel: ()=> { this.data_local.equipamentoNaoDevolvido = true; this.gravarRequest(null, false) }
            });
        } else {
            this.gravarRequest(null, false);    
        }
    },
    gravarRequest(novoStatus, isDuplicar) {        
        if (this.formularioValido(novoStatus === 'EXECUTADA')) { 
            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');
            
            if (this.registroOriginal.hasOwnProperty("id")) {
                params.append('method', 'Update');      
                params.append('id', this.data_local.id);                    
            }  else {
                if (isDuplicar) {
                    params.append('method', 'DUPLICAR');      
                } else {
                    params.append('method', 'Insert');      
                }
                novoStatus = 'ABERTA';  
            }  

            params.append('idCliente', this.data_local.idCliente);   
            if (this.data_local.idVeiculo) {
                params.append('idVeiculo', this.data_local.idVeiculo);      
            }
            params.append('placa', this.data_local.placa);      
            params.append('descricao', this.data_local.descricao);  
            
            if (this.data_local.textoAprovacao) {
                params.append('textoAprovacao', this.data_local.textoAprovacao);  
            }
            
            if (this.data_local.textoAprovacao) {
                params.append('textoAprovacao', this.data_local.textoAprovacao);  
            }

            if (this.data_local.emailAssinaturaEletronica) {                
                params.append('emailAssinaturaEletronica', this.data_local.emailAssinaturaEletronica);  
            }

            if (this.data_local.idTecnico) {
                params.append('idTecnico', this.data_local.idTecnico);      
            }
            params.append('tipo', this.data_local.tipo);      
            params.append('resultado', this.data_local.resultado);      
            params.append('dataAgendada', this.data_local.dataAgendada);      
            params.append('horaAgendada', this.data_local.horaAgendada);     
            if (this.data_local.dataExecutada) {
                params.append('dataExecutada', this.data_local.dataExecutada);      
                params.append('horaExecutada', this.data_local.horaExecutada);     
            }
            if (novoStatus) {
                params.append('status', novoStatus);      
            } else {
                params.append('status', this.data_local.status);      
            }
            
            params.append('installSimcard', this.data_local.installSimcard);      
            params.append('installSimcardExecutado', this.data_local.installSimcardExecutado);      
            params.append('installModulo', this.data_local.installModulo);      
            params.append('installBloqueio', this.data_local.installBloqueio);      
            params.append('installBotaoPanico', this.data_local.installBotaoPanico);      
            params.append('installSensorOdometro', this.data_local.installSensorOdometro);      
            params.append('installSensorRPM', this.data_local.installSensorRPM);      
            params.append('installSensorTanque', this.data_local.installSensorTanque);      
            params.append('installSensorSimples', this.data_local.installSensorSimples);      
            params.append('installAdaptadorLeitorCAN', this.data_local.installAdaptadorLeitorCAN);      
            params.append('installLeitorCAN', this.data_local.installLeitorCAN);      
            params.append('installIdentificadorMotorista', this.data_local.installIdentificadorMotorista);      
            params.append('installTeclado', this.data_local.installTeclado);      
            params.append('installSensorRS232', this.data_local.installSensorRS232);      
            params.append('installModuloExecutado', this.data_local.installModuloExecutado);
            params.append('installBloqueioExecutado', this.data_local.installBloqueioExecutado);
            params.append('installBotaoPanicoExecutado', this.data_local.installBotaoPanicoExecutado);
            params.append('installSensorOdometroExecutado', this.data_local.installSensorOdometroExecutado);
            params.append('installSensorRPMExecutado', this.data_local.installSensorRPMExecutado);
            params.append('installSensorTanqueExecutado', this.data_local.installSensorTanqueExecutado);
            params.append('installSensorSimplesExecutado', this.data_local.installSensorSimplesExecutado);
            params.append('installAdaptadorLeitorCANExecutado', this.data_local.installAdaptadorLeitorCANExecutado);
            params.append('installLeitorCANExecutado', this.data_local.installLeitorCANExecutado);
            params.append('installIdentificadorMotoristaExecutado', this.data_local.installIdentificadorMotoristaExecutado);
            params.append('installTecladoExecutado', this.data_local.installTecladoExecutado);
            params.append('installSensorRS232Executado', this.data_local.installSensorRS232Executado);
            params.append('verificacaoCobranca', this.data_local.verificacaoCobranca);      
            params.append('desintalacaoCobranca', this.data_local.desintalacaoCobranca);    
            if (this.data_local.codigoModuloInstalado) {
                params.append('codigoModuloInstalado', this.data_local.codigoModuloInstalado);      
            }
            if (this.data_local.codigoModuloDesinstalado) {
                params.append('codigoModuloDesinstalado', this.data_local.codigoModuloDesinstalado);      
            }
            params.append('isCentral', this.data_local.isCentral);      
            if (this.data_local.isCentral) {
                params.append('idCentral', this.data_local.idCentral);      
            }
            params.append('installNoCliente', this.data_local.installNoCliente);      
            params.append('installKitInstalacao', this.data_local.installKitInstalacao);      
            params.append('desativarAutomaticamente', this.data_local.desativarAutomaticamente); 
            params.append('cobrarKitBloqueio', this.data_local.cobrarKitBloqueio);      
            params.append('cobrarKitBotaoPanico', this.data_local.cobrarKitBotaoPanico);      
            
            if (this.data_local.valorOS) {     
                params.append('valorOS', this.data_local.valorOS);
            }

            if (this.data_local.codigoModulo1Reservado) {
                params.append('codigoModulo1Reservado', this.data_local.codigoModulo1Reservado);      
            }  

            if (this.data_local.codigoModulo2Reservado) {
                params.append('codigoModulo2Reservado', this.data_local.codigoModulo2Reservado);      
            }     
            
            if (this.data_local.custoTecnicoTerceirizado) {     
                params.append('custoTecnicoTerceirizado', this.data_local.custoTecnicoTerceirizado);
            }

            if (this.data_local.custoFrete) {     
                params.append('custoFrete', this.data_local.custoFrete);
            }

            if (this.data_local.faturaPagamentoCustoViagem) {     
                params.append('faturaPagamentoCustoViagem', this.data_local.faturaPagamentoCustoViagem);
            }

            params.append('equipamentoNaoDevolvido', this.data_local.equipamentoNaoDevolvido);
            if (this.data_local.equipamentoNaoDevolvido && this.data_local.dataPrevisaoDevolucaoEquipamento) {                
                params.append('dataPrevisaoDevolucaoEquipamento', this.data_local.dataPrevisaoDevolucaoEquipamento);
            }

            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                    this.$router.push({ path: '/' });
                } else {                    
                    if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {
                        if (!this.registroOriginal.hasOwnProperty("id")) {                            
                            this.$router.push({ name: NAME_ROUTE_SEARCH, params: {numeroOrdemServico: response.data.numeroOS } })
                        } else {
                            this.$vs.notify({ title: "Mensagem", text: response.data.mensagem, iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                            this.$router.push({ name: NAME_ROUTE_SEARCH })
                        }
                    }                   
                }
            })
            .catch((error) => { 
                this.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        }
         
    },
    voltar() {
        this.$router.push({
            name: NAME_ROUTE_SEARCH                     
        })
    },
    valorValido(valor){
        if (!valor && valor !== 0) {
            return false;
        }
        
        return true
    },
    formularioValido(isExecucao) {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];

        if (!this.data_local.tipo){ this.errorsValidacao.push("Tipo obrigatório."); }
        if (!this.data_local.placa){ this.errorsValidacao.push("Placa obrigatório."); }
        if (!this.data_local.dataAgendada){ this.errorsValidacao.push("Data obrigatório."); }
        if (!this.data_local.horaAgendada){ this.errorsValidacao.push("Hora obrigatório."); }
        if (!this.data_local.descricao){ this.errorsValidacao.push("Descrição obrigatório."); }

        let perfilLogado = this.$store.state.AppActiveUser.perfil;
        if (perfilLogado === "SUPERVISOR") {
            if (this.data_local.valorOS === 0 && this.data_local.valorCalculadoOS > 0) {
                this.errorsValidacao.push("Você não tem permissão para ZERAR o valor da Ordem de Serviço que tem o valor calulado de R$ " + this.data_local.valorCalculadoOS);
            }
        }

        if (this.data_local.tipo === 'INSTALACAO') {
            if (!this.data_local.installModulo) { this.errorsValidacao.push("Toda instalação de veículo novo deve ter a opção [Instalar Rastreador] selecionada em Serviços Para Executar."); }

            if (isExecucao && !this.data_local.codigoModuloInstalado) { this.errorsValidacao.push("Informe o módulo instalado."); }

            if (isExecucao && !this.data_local.installModuloExecutado) { this.errorsValidacao.push("Toda instalação de veículo novo deve ter a opção [Instalar Rastreador] selecionada em Serviços Executados."); }

            this.data_local.codigoModuloDesinstalado = null;
        }

        if (this.data_local.tipo === 'INSTALACAO_ACESSORIO') {
            if (this.data_local.installModulo) { this.errorsValidacao.push("Na instalação de acessório a opção [Instalar Rastreador] selecionada NÃO deve ser selecionada."); }

            let algumPlanejado  = this.data_local.installBloqueio || this.data_local.installBotaoPanico || this.data_local.installSensorOdometro || this.data_local.installSensorRPM || this.data_local.installSensorTanque || this.data_local.installSensorSimples || this.data_local.installAdaptadorLeitorCAN || this.data_local.installLeitorCAN || this.data_local.installIdentificadorMotorista || this.data_local.installTeclado || this.data_local.installSensorRS232;
            if (!algumPlanejado) { this.errorsValidacao.push("Selecione pelo menos um acessório a instalar."); }

            let algumExecutado  = this.data_local.installBloqueioExecutado || this.data_local.installBotaoPanicoExecutado || this.data_local.installSensorOdometroExecutado || this.data_local.installSensorRPMExecutado || this.data_local.installSensorTanqueExecutado || this.data_local.installSensorSimplesExecutado || this.data_local.installAdaptadorLeitorCANExecutado || this.data_local.installLeitorCANExecutado || this.data_local.installIdentificadorMotoristaExecutado || this.data_local.installTecladoExecutado || this.data_local.installSensorRS232Executado;
            if (isExecucao && !algumExecutado) { this.errorsValidacao.push("Selecione pelo menos um item instalado."); }
            this.data_local.codigoModuloInstalado = null;
        }

        if (this.tipo === 'DESINSTALACAO') {
            let algumPlanejado  = this.data_local.installModulo || this.data_local.installBloqueio || this.data_local.installBotaoPanico || this.data_local.installSensorOdometro || this.data_local.installSensorRPM || this.data_local.installSensorTanque || this.installSensorSimples || this.data_local.installAdaptadorLeitorCAN || this.data_local.installLeitorCAN || this.data_local.installIdentificadorMotorista || this.data_local.installTeclado || this.data_local.installSensorRS232;
            if (!algumPlanejado) { this.errorsValidacao.push("Selecione pelo menos um item a desinstalar."); }

            let algumExecutado  = this.data_local.installModuloExecutado || this.data_local.installBloqueioExecutado || this.data_local.installBotaoPanicoExecutado || this.data_local.installSensorOdometroExecutado || this.data_local.installSensorRPMExecutado || this.data_local.installSensorTanqueExecutado || this.data_local.installSensorSimplesExecutado || this.data_local.installAdaptadorLeitorCANExecutado || this.data_local.installLeitorCANExecutado || this.data_local.installIdentificadorMotoristaExecutado || this.data_local.installTecladoExecutado || this.data_local.installSensorRS232Executado;
            if (isExecucao && !algumExecutado) { this.errorsValidacao.push("Selecione pelo menos um item desinstalado."); }

            if (isExecucao && !this.data_local.codigoModuloDesinstalado) { this.errorsValidacao.push("Informe o módulo desinstalado."); }
            this.data_local.codigoModuloInstalado = null;
        }

        if (this.tipo === 'VERIFICACAO') {
            let algumPlanejado  = this.data_local.installModulo || this.data_local.installBloqueio || this.data_local.installBotaoPanico || this.data_local.installSensorOdometro || this.data_local.installSensorRPM || this.data_local.installSensorTanque || this.installSensorSimples || this.data_local.installAdaptadorLeitorCAN || this.data_local.installLeitorCAN || this.data_local.installIdentificadorMotorista || this.data_local.installTeclado || this.data_local.installSensorRS232;
            if (!algumPlanejado) { this.errorsValidacao.push("Selecione pelo menos um item a verificar."); }

            let algumExecutado  = this.data_local.installModuloExecutado || this.data_local.installBloqueioExecutado || this.data_local.installBotaoPanicoExecutado || this.data_local.installSensorOdometroExecutado || this.data_local.installSensorRPMExecutado || this.data_local.installSensorTanqueExecutado || this.data_local.installSensorSimplesExecutado || this.data_local.installAdaptadorLeitorCANExecutado || this.data_local.installLeitorCANExecutado || this.data_local.installIdentificadorMotoristaExecutado || this.data_local.installTecladoExecutado || this.data_local.installSensorRS232Executado;
            if (isExecucao && !algumExecutado) { this.errorsValidacao.push("Selecione pelo menos um item verificado."); }

            if (isExecucao && !this.data_local.codigoModuloInstalado && this.data_local.codigoModuloDesinstalado) { this.errorsValidacao.push("Informe o módulo instalado."); }
            if (isExecucao && this.data_local.codigoModuloInstalado && !this.data_local.codigoModuloDesinstalado) { this.errorsValidacao.push("Informe o módulo desinstalado."); }
        }

        if (this.tipo === 'SUBSTITUICAO') {
            let algumPlanejado  = this.data_local.installModulo || this.data_local.installBloqueio || this.data_local.installBotaoPanico || this.data_local.installSensorOdometro 
              || this.data_local.installSensorRPM || this.data_local.installSensorTanque || this.installSensorSimples || this.data_local.installAdaptadorLeitorCAN 
              || this.data_local.installLeitorCAN || this.data_local.installIdentificadorMotorista || this.data_local.installTeclado || this.data_local.installSensorRS232 
              || this.data_local.installSimcard;
            if (!algumPlanejado) { this.errorsValidacao.push("Selecione pelo menos um item a substituir."); }

            let algumExecutado  = this.data_local.installModuloExecutado || this.data_local.installBloqueioExecutado || this.data_local.installBotaoPanicoExecutado 
              || this.data_local.installSensorOdometroExecutado || this.data_local.installSensorRPMExecutado || this.data_local.installSensorTanqueExecutado 
              || this.data_local.installSensorSimplesExecutado || this.data_local.installAdaptadorLeitorCANExecutado || this.data_local.installLeitorCANExecutado 
              || this.data_local.installIdentificadorMotoristaExecutado || this.data_local.installTecladoExecutado || this.data_local.installSensorRS232Executado || this.data_local.installSimcardExecutado;
            if (isExecucao && !algumExecutado) { this.errorsValidacao.push("Selecione pelo menos um item substituído."); }

            if (this.data_local.installModulo && isExecucao && !this.data_local.codigoModuloInstalado && this.data_local.codigoModuloDesinstalado) { this.errorsValidacao.push("Informe o módulo instalado."); }
            if (this.data_local.installModulo && isExecucao && this.data_local.codigoModuloInstalado && !this.data_local.codigoModuloDesinstalado) { this.errorsValidacao.push("Informe o módulo desinstalado."); }
        }

        if (this.data_local.isCentral) {
            if (!this.data_local.idCentral) { this.errorsValidacao.push("Selecione qual a Central de Rastreamento da OS."); }
        }

        if (!this.data_local.idCliente) { this.errorsValidacao.push("Cliente obrigatório."); }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    formularioValidoAlteracaoStatus(novoStatus) {
        this.$refs.errosComponent.iniciar();
        this.errorsValidacao = [];

        if (novoStatus === 'FINALIZADA') {
            let algumExecutado  = this.data_local.installModuloExecutado || this.data_local.installBloqueioExecutado || this.data_local.installBotaoPanicoExecutado 
             || this.data_local.installSensorOdometroExecutado || this.data_local.installSensorRPMExecutado || this.data_local.installSensorTanqueExecutado 
             || this.data_local.installSensorSimplesExecutado || this.data_local.installAdaptadorLeitorCANExecutado || this.data_local.installLeitorCANExecutado 
             || this.data_local.installIdentificadorMotoristaExecutado || this.data_local.installTecladoExecutado || this.data_local.installSensorRS232Executado
             || this.data_local.installSimcardExecutado;
            if (!algumExecutado) {
                this.errorsValidacao.push("Selecione algum item executado");
            }

            if (!this.valorValido(this.data_local.valorOS)){
                this.errorsValidacao.push("Valor da OS inválido.");
            }
        } else if (this.formularioValido(novoStatus === 'EXECUTADA')) { 
            if (novoStatus === 'EXECUTADA') {
                let algumExecutado  = this.data_local.installModuloExecutado || this.data_local.installBloqueioExecutado || this.data_local.installBotaoPanicoExecutado 
                || this.data_local.installSensorOdometroExecutado || this.data_local.installSensorRPMExecutado || this.data_local.installSensorTanqueExecutado 
                || this.data_local.installSensorSimplesExecutado || this.data_local.installAdaptadorLeitorCANExecutado || this.data_local.installLeitorCANExecutado 
                || this.data_local.installIdentificadorMotoristaExecutado || this.data_local.installTecladoExecutado || this.data_local.installSensorRS232Executado
                || this.data_local.installSimcardExecutado;
                if (!algumExecutado) {
                    this.errorsValidacao.push("Selecione algum item executado");
                }
                if (!this.data_local.dataExecutada){
                    this.errorsValidacao.push("Data de execução obrigatória.");
                }
                if (!this.data_local.horaExecutada){
                    this.errorsValidacao.push("Hora de execução obrigatória.");
                }
                if (!this.data_local.resultado){
                    this.errorsValidacao.push("Resultado da execução obrigatória.");
                }
            }
        } else if (novoStatus === 'PENDENTE' || novoStatus === 'CANCELADA') {
            //TODO registrar o motivo.
            console.log('novoStatus=' + novoStatus);
        }

        if (!this.errorsValidacao.length) {
            return true;
        }   
        return false;
    },
    fetchListaClientes() {     
        const params = new URLSearchParams();
        params.append('method', 'FindAll');
        params.append('orderDirection', 'ASC');
        params.append('outputFormat', 'JSON');

        if (this.data_local.isCentral && this.data_local.idCentral) {
            params.append('idCentral', this.data_local.idCentral);
        } else if (this.data_local.isCentral && !this.data_local.idCentral) {
            this.data_local.idCliente = null;
            this.listaClientes = [];
            return;
        }
        
        this.$vs.loading() 
        axios.post("/ClientesRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaClientes = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    }, 
    fetchListaEmpresas() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        
        axios.post("/ListarEmpresas", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaEmpresas = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    }, 
    fetchListaTecnicos() {     
        this.$vs.loading() 
        const params = new URLSearchParams();
        params.append('method', 'FindAll');
        params.append('orderDirection', 'ASC');
        params.append('outputFormat', 'JSON');
        
        axios.post("/TecnicoRest", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.listaTecnicos = response.data;                            
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchLista - " + error); 
        })    
                            
    },
    fetchListaArquivosAnexos() {     
         const params = new URLSearchParams();
        params.append('method', 'LISTAR_DOCUMENTOS');
        params.append('outputFormat', 'JSON');
        params.append('idOrdemServico', this.registroOriginal.id);

        axios.post(URL_SERVLET, params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {                             
            this.listaArquivoAnexos = response.data;    
        })
        .catch((error) => { 
            /* eslint-disable no-console */ console.log("Erro fetchListaDocumentos " + error);        
        })       
                            
    },
    excluirAnexo(itemSelecionado) {
        if (itemSelecionado) {
            let currentObj = this;
            this.$vs.loading(); 

            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');                
            params.append('idDocumento', itemSelecionado.id);   
            params.append('nomeDocumento', itemSelecionado.nomeDocumento);   
            params.append('idOrdemServico', this.registroOriginal.id);
            params.append('method', 'EXCLUIR_DOCUMENTOS');      
                    
            axios.post(URL_SERVLET, params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/' });
                } else {
                    currentObj.$vs.loading.close();

                    if (response.data.mensagem.indexOf("erro") != -1) {
                            this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.mensagem, acceptText: 'OK'})
                    } else {
                        this.fetchListaArquivosAnexos();
                    }                   
                }
            })
            .catch((error) => { 
                currentObj.$vs.loading.close();
                /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
            })  
        } else {
            this.$vs.dialog({ color: 'danger', title: 'Erro', text: 'Selecione um anexo', acceptText: 'OK'})
        }
    },  
    successUploadFile(evento){              
        var resultadoJSON = JSON.parse(evento.target.response);
        this.mensagemResultadoUpload = resultadoJSON.mensagem;
        this.exibirMensagemResultadoUpload = true;
        this.dadosUpload.legendaArquivo = null;
        this.fetchListaArquivosAnexos(); 
    },
    limparMsgErroUpload() {
      this.mensagemResultadoUpload = null;
      this.exibirMensagemResultadoUpload = false;
    },
    verDocumento(arquivoAnexo) { 
        if (arquivoAnexo ) {
            window.open(arquivoAnexo.caminhoDocumento, "_blank");    
        } 
    },
    exportarRelatorio(pTipoFormato) {
        this.$vs.loading();
        
        const params = new URLSearchParams();            
        params.append('outputFormat', 'JSON');
        params.append('pIdCliente', this.data_local.idCliente);   
        params.append('nomeRelatorio', "OrdemServico");
        params.append('exportarTipo', pTipoFormato);
        params.append('idOrdemServico', this.data_local.id);

        axios.post("/RelatoriosJasper", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/login' });
            } else {                                                
                
                var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                var fileLink = document.createElement('a');
                fileLink.href = fileURL;
                fileLink.setAttribute('download', 'Ordem Servico - ' + this.data_local.numeroOS + '.' + pTipoFormato.toLowerCase());
                document.body.appendChild(fileLink);
                fileLink.click();
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
            this.$vs.loading.close();
        }) 

    },
    gerarRelatorio() {
        this.$vs.loading();
        const params = new URLSearchParams();            
        params.append('outputFormat', 'JSON');
        params.append('pIdCliente', this.data_local.idCliente);   
        params.append('nomeRelatorio', "OrdemServico");
        params.append('exportarTipo', 'PDF');
        params.append('idOrdemServico', this.data_local.id);

        axios.post("/RelatoriosJasper", params, {              
                    credentials: 'include', responseEncoding: 'iso88591', withCredentials: true,  responseType: 'arraybuffer' } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                this.$router.push({ path: '/login' });
            } else {                                                
                this.dadosPDF = new Uint8Array(response.data);
                
                if (!this.dadosPDF || this.dadosPDF.length < 2000) {
                    this.dadosPDF = null;
                } 
            }
        })
        .catch((error) => { 
            /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
            this.$vs.loading.close();
        }) 
    },
    baixarPagamento() {
        this.$vs.dialog({
          type: 'confirm', color: 'warning', title: 'Atenção', text: 'Deseja dar baixar no pagamento ?', acceptText: 'Sim', cancelText: 'Não', accept: this.baixarPagamentoRequest
        });
    },
    baixarPagamentoRequest() {
        this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('idsOS', this.data_local.id);   
                
        axios.post('/BaixaPagamentoFaturaOS', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })  
    }, 
    fetchTabelaPrecos() {   
        
        const params = new URLSearchParams();
        params.append('outputFormat', 'JSON');
        params.append('pIdCliente', this.data_local.idCliente);   
        
        axios.post("/RecuperarTabelaPrecoCliente", params, {              
                credentials: 'include',
                responseEncoding: 'iso88591',
                withCredentials: true } )
        .then((response) => {
            this.$vs.loading.close();
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {                
                this.popUpTabelaPrecos.exibir = true;  
                this.tabelaPreco = response.data;                                
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - fetchListaVeiculos - " + error); 
        })    
                            
    },
    enviarParaAprovacao() {
        if (this.formularioValido(false)) { 

            this.$refs.errosComponent.iniciar();
            this.errorsValidacao = [];
            if (!this.data_local.emailAssinaturaEletronica) { this.errorsValidacao.push("E-mail do aprovador obrigatório."); }
            if (!this.data_local.textoAprovacao) { this.errorsValidacao.push("Texto para envio de aprovação do cliente obrigatório."); }

            if (this.errorsValidacao.length) {
                return;
            }               

            this.$vs.loading();  
            const params = new URLSearchParams();        
            params.append('outputFormat', 'JSON');     
            params.append('method', 'ENVIAR_PARA_APROVACAO');        
            params.append('id', this.data_local.id);              
            params.append('idCliente', this.data_local.idCliente);
            params.append('numeroOS', this.data_local.numeroOS);      

            if (this.data_local.idVeiculo) {
                params.append('idVeiculo', this.data_local.idVeiculo);      
            }
            params.append('placa', this.data_local.placa);      
            params.append('descricao', this.data_local.descricao);  
            
            if (this.data_local.textoAprovacao) {
                params.append('textoAprovacao', this.data_local.textoAprovacao);  
            }

            if (this.data_local.emailAssinaturaEletronica) {                
                params.append('emailAssinaturaEletronica', this.data_local.emailAssinaturaEletronica);  
            }
            if (this.data_local.idTecnico) {
                params.append('idTecnico', this.data_local.idTecnico);      
            }
            params.append('tipo', this.data_local.tipo);      
            params.append('resultado', this.data_local.resultado);      
            params.append('dataAgendada', this.data_local.dataAgendada);      
            params.append('horaAgendada', this.data_local.horaAgendada);     
            if (this.data_local.dataExecutada) {
                params.append('dataExecutada', this.data_local.dataExecutada);      
                params.append('horaExecutada', this.data_local.horaExecutada);     
            }
          
            params.append('status', this.data_local.status);      
            
            params.append('installModulo', this.data_local.installModulo);      
            params.append('installBloqueio', this.data_local.installBloqueio);      
            params.append('installBotaoPanico', this.data_local.installBotaoPanico);      
            params.append('installSensorOdometro', this.data_local.installSensorOdometro);      
            params.append('installSensorRPM', this.data_local.installSensorRPM);      
            params.append('installSensorTanque', this.data_local.installSensorTanque);      
            params.append('installSensorSimples', this.data_local.installSensorSimples);      
            params.append('installAdaptadorLeitorCAN', this.data_local.installAdaptadorLeitorCAN);      
            params.append('installLeitorCAN', this.data_local.installLeitorCAN);      
            params.append('installIdentificadorMotorista', this.data_local.installIdentificadorMotorista);      
            params.append('installTeclado', this.data_local.installTeclado);      
            params.append('installSensorRS232', this.data_local.installSensorRS232);      
            params.append('installModuloExecutado', this.data_local.installModuloExecutado);
            params.append('installBloqueioExecutado', this.data_local.installBloqueioExecutado);
            params.append('installBotaoPanicoExecutado', this.data_local.installBotaoPanicoExecutado);
            params.append('installSensorOdometroExecutado', this.data_local.installSensorOdometroExecutado);
            params.append('installSensorRPMExecutado', this.data_local.installSensorRPMExecutado);
            params.append('installSensorTanqueExecutado', this.data_local.installSensorTanqueExecutado);
            params.append('installSensorSimplesExecutado', this.data_local.installSensorSimplesExecutado);
            params.append('installAdaptadorLeitorCANExecutado', this.data_local.installAdaptadorLeitorCANExecutado);
            params.append('installLeitorCANExecutado', this.data_local.installLeitorCANExecutado);
            params.append('installIdentificadorMotoristaExecutado', this.data_local.installIdentificadorMotoristaExecutado);
            params.append('installTecladoExecutado', this.data_local.installTecladoExecutado);
            params.append('installSensorRS232Executado', this.data_local.installSensorRS232Executado);
            params.append('verificacaoCobranca', this.data_local.verificacaoCobranca);      
            params.append('desintalacaoCobranca', this.data_local.desintalacaoCobranca);    
            if (this.data_local.codigoModuloInstalado) {
                params.append('codigoModuloInstalado', this.data_local.codigoModuloInstalado);      
            }
            if (this.data_local.codigoModuloDesinstalado) {
                params.append('codigoModuloDesinstalado', this.data_local.codigoModuloDesinstalado);      
            }
            params.append('isCentral', this.data_local.isCentral);      
            if (this.data_local.isCentral) {
                params.append('idCentral', this.data_local.idCentral);      
            }
            params.append('installNoCliente', this.data_local.installNoCliente);      
            params.append('installKitInstalacao', this.data_local.installKitInstalacao);      
            params.append('desativarAutomaticamente', this.data_local.desativarAutomaticamente); 
            params.append('cobrarKitBloqueio', this.data_local.cobrarKitBloqueio);      
            params.append('cobrarKitBotaoPanico', this.data_local.cobrarKitBotaoPanico);      

            if (this.data_local.valorOS) {     
                params.append('valorOS', this.data_local.valorOS);
            }

            if (this.data_local.codigoModulo1Reservado) {
                params.append('codigoModulo1Reservado', this.data_local.codigoModulo1Reservado);      
            }  

            if (this.data_local.codigoModulo2Reservado) {
                params.append('codigoModulo2Reservado', this.data_local.codigoModulo2Reservado);      
            }
            
            if (this.data_local.custoTecnicoTerceirizado) {     
                params.append('custoTecnicoTerceirizado', this.data_local.custoTecnicoTerceirizado);
            }

            if (this.data_local.custoFrete) {     
                params.append('custoFrete', this.data_local.custoFrete);
            }

            if (this.data_local.faturaPagamentoCustoViagem) {     
                params.append('faturaPagamentoCustoViagem', this.data_local.faturaPagamentoCustoViagem);
            }

            axios.post(URL_SERVLET, params, {              
                        credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
            .then((response) => {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {                
                    this.$router.push({ path: '/login' });
                } else {                                                
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                    } else {                  
                        this.$vs.notify({ title: "Mensagem", text: response.data.mensagem, iconPack: 'feather', icon: 'icon-alert-circle', color: 'success' });
                        this.$router.push({ name: NAME_ROUTE_SEARCH })
                    }      
                }
            })
            .catch((error) => { 
                /* eslint-disable no-console */  console.log("EFETUAR LOGOFF! - gravar - " + error); 
                this.$vs.loading.close();
            }) 
        }
    },
    criarContaAPagar(){
        
         this.$vs.loading();  
        const params = new URLSearchParams();        
        params.append('outputFormat', 'JSON');
        params.append('idsOS', this.data_local.id);   
        params.append('acao', 'CRIAR_CONTA_A_PAGAR');   
                
        axios.post('/BaixaPagamentoFaturaOS', params, {              
                credentials: 'include', responseEncoding: 'iso88591', withCredentials: true } )
        .then((response) => {
            if (response.data.hasOwnProperty("codigo") && response.data.codigo === "99" ) {
                this.$router.push({ path: '/' });
            } else {
                this.$vs.loading.close();
                if (response.data.hasOwnProperty("error")) {
                        this.$vs.dialog({ color: 'danger', title: 'Erro', text: response.data.error, acceptText: 'OK'})
                } else {
                    this.$router.push({ name: NAME_ROUTE_SEARCH })
                }                   
            }
        })
        .catch((error) => { 
            this.$vs.loading.close();
            /* eslint-disable no-console */ console.log("EFETUAR LOGOFF! - gravar - " + error); 
        })      
    
    }
  }
}
</script>

<style>  
  .style-chooser .vs__dropdown-menu {
    max-height: 150px;
  }
</style>

<style scoped>  
  .tabelaComScroll {      
      overflow: auto;  
      height: calc(100vh - 300px);
      position: relative;      
    } 
</style>

<style lang="scss" scoped>
  .ag-grid-altura{
    height: calc(100vh - 305px);
  }  

.imgHolder {
    display:inline-block;    
    width: 210px;
    height: 140px;
    margin-right: 10px;
}

.imgHolder img{
    display:block;
    margin:auto;
}

.imgHolder span {
    display: inline-block;
    border: 0.1em solid rgba(0, 0, 0, .1);
    -webkit-background-clip: padding-box; /* for Safari */
    background-clip: padding-box;    
    font-size: 0.7rem;
    text-align: center;
    width: 140px;    
}
</style>
